export const useSliderVisibility = (scrollRef: Ref<HTMLElement | null>) => {
  const showLeftArrow = ref(false);
  const showRightArrow = ref(false);

  const checkVisibility = () => {
    if (!scrollRef.value) return;

    const slider = scrollRef.value;
    const scrollRect = slider.getBoundingClientRect();
    const firstItem = slider.firstElementChild;
    const lastItem = slider.lastElementChild;

    if (firstItem && lastItem) {
      const firstItemRect = firstItem.getBoundingClientRect();
      const lastItemRect = lastItem.getBoundingClientRect();

      showLeftArrow.value = firstItemRect.left < scrollRect.left - 10;
      showRightArrow.value = lastItemRect.right > scrollRect.right + 10;
    }
  };

  onMounted(() => {
    checkVisibility();
    window.addEventListener('resize', checkVisibility);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', checkVisibility);
  });

  return {
    showLeftArrow,
    showRightArrow,
    checkVisibility,
  };
}
