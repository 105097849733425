import { ref } from 'vue';

export const useScroll = () => {

  const scrollRef = ref<HTMLElement | null>(null);

  const scrollLeft = (pixels: number): void => {
    if (scrollRef.value) {
      scrollRef.value.scrollBy({ left: -pixels, behavior: 'smooth' });
    }
  };

  const scrollRight = (pixels: number): void => {
    if (scrollRef.value) {
      scrollRef.value.scrollBy({ left: pixels, behavior: 'smooth' });
    }
  };

  return {
    scrollRef,
    scrollLeft,
    scrollRight,
  };
}
